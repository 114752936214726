import React, {useState, useEffect} from "react"
import {Link} from 'gatsby';
import {Container, Table, Button, Row, Col, Form, FormControl, Badge, Alert} from 'react-bootstrap'
import Layout from "../../components/layout"
import SEO from "../../components/seo";
import Loader from '../../components/loading';
import GenericPagination from '../../components/genericPagination';
import StandardModal from '../../components/modal';
import ActionLog from '../../components/actionLog';
import Fetch from "../../helpers/fetch";
import constants from '../../helpers/constants';
import { 
  DDMMYYYYFormat, DOSDDMMYYYYFormat
} from '../../helpers/dateTime';
import { isAdmin, isManager, isRedDotBespoke } from '../../helpers/roles'
import {replaceSpaceInPhoneNumber} from '../../helpers/string';

const OrderHistory = () => {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successAlertMsg, setSuccessAlertMsg] = useState('');
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [failAlertMsg, setFailAlertMsg] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [ordersArray, setOrdersArray] = useState([])
  const [pagination, setPagination] = useState({});
  const [rowLimit, setRowLimit] = useState(25);
  const [showModal, setShowModal] = useState(false);
  const [showNotificationModal, setShowSMSModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [actionLogs, setActionLogs] = useState([]);
  const [fittingConfirmDisabledState, setFittingConfirmDisabledState] = useState(false)
  const [collectionConfirmDisabledState, setCollectionConfirmDisabledState] = useState(false)

  const [notificationType, setNotificationType] = useState('');

  const [orderItem, setOrderItem] = useState({})

  const [page, setPage] = useState(1);
  const [phone, setPhone] = useState('');
  const [custId, setCustId] = useState('');
  const [custName, setCustName] = useState('');

  const getOrderHistory = (e) => {
    let queryString = '';
    let queryParams = [];
    let url = `${constants.BACKEND_URL}/orders/history`
    
    if(page) {
      queryParams.push(`page=${page}`)
    }
    if(custId) {
      queryParams.push(`custId=${custId}`)
    }
    if(custName) {
      queryParams.push(`custName=${custName}`)
    }
    if(phone) {
      queryParams.push(`phone=${phone}`)
    }
    if(queryParams.length > 0) {
      queryString = `?${queryParams.join('&')}`
    }
    setShowLoader(true)
    Fetch.get(`${url}${queryString}`).then(data => {
     setOrdersArray(data.data)
     setPagination(data.pagination)
     setShowLoader(false);
     setShowFailAlert(false);
     setShowSuccessAlert(false);
    })
  }

  const addToLog = (orderId, name, method, status=null) => {
    const url=`${constants.BACKEND_URL}/emai-log`
     const logData={
      orderId,
      name,
      method,
      status,
     }
     Fetch.post(url, JSON.stringify(logData)).then(data => {
     })
  }

  const getOrderItemFromOrdersArray = (orderItemId) => {
    if (ordersArray.length > 0) {
      const orderItem = ordersArray.filter(item => {
        return item.id == Number(orderItemId)
      })
      return orderItem[0];
    }
  }

  const searchPhone = (e) => {
    const sanitizedPhone = replaceSpaceInPhoneNumber(e.target.value)
    setPhone(sanitizedPhone);
    setPage(1);
  }

  const searchCustId = (e) => {
    setCustId(e.target.value);
    setPage(1);
  }

  const searchCustName = (e) => {
    setCustName(e.target.value);
    setPage(1);
  }

  const statusField = (item) => {
    const statusJSX = (
      <></>
    )
    if (item.hasItemsInRefund === "1") {
      return (
        <>
          <span className="oi oi-action-undo text-danger" title="Some items are pending refund/refunded"></span>
        </>
      )
    }
    if (item.fully_paid) {
      return (
        <>
          <span className="oi oi-check text-success" title="Completed">&nbsp;</span>
          {item.hasItemsInActiveFitGtd === "1" ? <span className="oi oi-project text-info" title="Fit Gtd Activated"></span>: <></>}
        </>
      )
    }
    if (item.deleted) {
      return (
        <>
          <span className="oi oi-ban text-danger" title="Deleted"></span>
        </>
      )
    }

    return statusJSX;

  }
  
  useEffect(() => {
    if (phone || custId || custName ) {
      const timeOutId = setTimeout(() => getOrderHistory(), constants.SEARCH_TIMEOUT_IN_MS);
      return () => clearTimeout(timeOutId);
    } else {
      getOrderHistory()
    }
    return () => {
      
    }
  }, [page, custId, custName, phone])

  const handleOnPaginationClick = (e) => {
    let target = e.target.dataset.goTo
    if (!target) {
      target = e.target.parentNode.dataset.goTo;
    }
    switch (target) {
      case "firstPage":
        setPage(1);
        break;
      case "prevPage":
        setPage(page-1);
        break;
      case "nextPage":
        setPage(page+1);
        break;
      case "lastPage":
        setPage(pagination.totalPages);
        break;
      default:
        setPage(target);
        break;
    }
  }

  const handleOnClearAllFilter = (e) => {
    setPage(1);
    setPhone('');
    setCustName('');
    setCustId('');
  }

  const handleOnFilterPillClick = (e) => {
    const filter = e.target.dataset.filter
    switch (filter) {
      case 'phone':
        setPhone('');
        break;
      case 'custId':
        setCustId('')
        break;
      case 'custName':
        setCustName('')
        break;
      default:
        break;
    }
  }

  const handleDeleteClick = (e) => {
    const id = e.target.dataset.deleteId;
    setOrderItem(getOrderItemFromOrdersArray(id))
    setShowModal(true);
  }

  const handleModalConfirm = () => {
    const url=`${constants.BACKEND_URL}/orders/${orderItem.id}`
    setShowLoader(true);
    Fetch.delete(url).then(data => {
     if (data.error) {
      setShowLoader(false);
      setShowFailAlert(true);
      setShowModal(false);
      setFailAlertMsg(data.error)
      return;  
    }
      setShowLoader(false);
      setShowModal(false);
      getOrderHistory()
    })
  }

  const handleModalClose = () => {
    setShowModal(false);
  }

  const handleSMSModalConfirm = () => {
    const url=`${constants.BACKEND_URL}/orders/notification/${orderItem.id}`
    const data={
      type: notificationType,
    }
    switch (notificationType) {
      case 'fitting':
        setFittingConfirmDisabledState(true);
        break;
      case 'collection':
        setCollectionConfirmDisabledState(true);
        break;
    
      default:
        break;
    }
    setShowLoader(false);
    Fetch.post(url, JSON.stringify(data)).then(data => {
     if (data.error) {
      setShowLoader(false);
      setShowFailAlert(true);
      setShowSMSModal(false);
      setFailAlertMsg(data.error)
      setFittingConfirmDisabledState(false);
      setCollectionConfirmDisabledState(false);
      return;  
     }
      setShowLoader(false);
      setShowSMSModal(false);
      setShowSuccessAlert(true);
      setSuccessAlertMsg(data.msg);
      setFittingConfirmDisabledState(false);
      setCollectionConfirmDisabledState(false);
    })
    
  }

  const handleSMSModalClose = () => {
    setShowSMSModal(false);
  }

  const handleLogModalClose = () => {
    setShowLogModal(false);
  }

  const handleOnLogViewClick = (e) => {
    const orderId = e.target.dataset.orderId;
    const url=`${constants.BACKEND_URL}/emai-log/${orderId}`;
    setShowLoader(true);
    Fetch.get(url).then(data => {
     setActionLogs(data.reverse());
     setShowLoader(false);

    })
    setShowLogModal(true);
  }

  const handleNotificationClick = (e) => {
    const id = e.target.dataset.orderId;
    const type = e.target.dataset.notificationType;
    setNotificationType(type);
    setOrderItem(getOrderItemFromOrdersArray(id));
    setShowSMSModal(true);
  }

  const handleReOpenClick = (e) => {
    const id = e.target.dataset.orderId;
    setOrderItem(getOrderItemFromOrdersArray(id))
    const url=`${constants.BACKEND_URL}/orders/${id}`
    const data={
      deleted: false,
    }
    setShowLoader(true);
    Fetch.patch(url, JSON.stringify(data)).then(data => {
      if (data.error) {
        setShowLoader(false);
        setShowFailAlert(true);
        setShowSMSModal(false);
        setFailAlertMsg(data.error)
        return;  
      }
      getOrderHistory()
      addToLog(id, 'Reopen Order', 'via Backend')
      setShowLoader(false);

    })
  }

  const handleRefundModalConfirm = (e) => {
    const url=`${constants.BACKEND_URL}/orders/refund/${orderItem.id}`
    const data={}
    setShowLoader(true);
    Fetch.patch(url, JSON.stringify(data)).then(data => {
      if (data.error) {
        setShowLoader(false);
        setShowRefundModal(false);
        setShowFailAlert(true);
        setFailAlertMsg(data.error);
        addToLog(orderItem.id, 'Refund Order', 'via Backend', 'Fail')
        return;  
      }
      getOrderHistory()
      addToLog(orderItem.id, 'Refund Order', 'via Backend', 'Success')
      setShowLoader(false);
      setShowRefundModal(false);
      setShowSuccessAlert(true);
      setSuccessAlertMsg("Order has been marked as ready for refund")
    })
  }

  const handleRefundModalClose = (e) => {
    setShowRefundModal(false);
  }
  
  return (
    <Layout pageInfo={{ pageName: "orderHistory" }}>
      <Loader show={showLoader}></Loader>
      <SEO title="Order History" />
      <Row>
        <Col className="text-center">
          <h5>Order History</h5>
        </Col>
      </Row>
      
      <Container fluid className="mt-3">
        <Row>
          <Col>
            <Alert dismissible variant="success" onClose={() => setShowSuccessAlert(false)} show={showSuccessAlert}>{successAlertMsg}</Alert>
            <Alert dismissible variant="danger" onClose={() => setShowFailAlert(false)} show={showFailAlert}>{failAlertMsg}</Alert>
          </Col>
        </Row>
        <Row className="mb-2" >
          <Col sm={12} lg={4}>
            <Form.Group as={Row} >
              <Form.Label  column sm={3} className="text-right">Cust Id</Form.Label>
              <Col>
                <FormControl
                  type="text"
                  placeholder="Search..." 
                  value={custId}               
                  onChange={searchCustId}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col  sm={12} lg={4}>
            <Form.Group as={Row} >
              <Form.Label  column sm={3} lg={4} className="text-right">Cust Name</Form.Label>
              <Col>
                <FormControl
                  type="text"
                  placeholder="Search..." 
                  value={custName}               
                  onChange={searchCustName}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col sm={12} lg={4}>
            <Form.Group as={Row} >
              <Form.Label  column sm={3} className="text-right">Cust HP</Form.Label>
              <Col>
                <FormControl
                  type="text"
                  placeholder="Search..." 
                  value={phone}               
                  onChange={searchPhone}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <small><a href="#" onClick={handleOnClearAllFilter}>Clear All Filter</a> </small>
            <span onClick={handleOnFilterPillClick}>
              {phone && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Cust HP: {phone} 
                  <span className="oi oi-circle-x ml-2" data-filter="phone" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              {custName && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Cust Name: {custName} 
                  <span className="oi oi-circle-x ml-2" data-filter="custName" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              {custId && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Cust Id: {custId} 
                  <span className="oi oi-circle-x ml-2" data-filter="custId" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table responsive className="text-center">
              <thead>
                <tr>
                  <th style={{width:"1%"}}>Status</th>
                  <th>Order ID</th>
                  <th>Cust ID</th>
                  <th>Name</th>
                  <th>Consultant</th>
                  <th className="text-right">Order Amount</th>
                  <th>Order Date</th>
                  <th>Completion Date</th>
                  <th className="text-right">Deposit/ Payment</th>
                  <th className="text-right">Balance</th>
                  <th className="text-left">Order Actions</th>
                  {isRedDotBespoke() ? <th>Notification</th> : <></>}
                  <th>Action Log</th>
                </tr>
              </thead>
              <tbody>
                {ordersArray && Array.isArray(ordersArray) ? 
                  ordersArray.map(item => {
                    return (
                      <tr key={item.id}>
                        <td>{statusField(item)}</td>
                        <td>
                          <Link 
                            to={`/prodsheet`}
                            state={{ orderId: item.id }}
                          >
                            {item.id}
                          </Link>
                          </td>
                        <td>
                          <Link 
                            to={`/customers/summary`}
                            state={{ custId: item.custid }}
                          >
                            {item.custid}
                          </Link>
                        </td>
                        <td>{item.name}</td>
                        <td>{item.consultant_name}</td>
                        <td className="text-right">${(Number(item.deposit) + Number(item.balance)).toFixed(2)}</td>
                        <td className="text-center">{DOSDDMMYYYYFormat(item.time)}</td>
                        <td className="text-center">{DDMMYYYYFormat(item.balance_paid)}</td>
                        <td className="text-right">${Number(item.deposit).toFixed(2)}</td>
                        <td className={`${item.balance > 0 ? 'text-danger' : 'text-success'} text-right`}>${Number(item.balance).toFixed(2)}</td>
                        <td className="text-left">
                          <Link 
                            to={`/orders/salesOrder?custId=${item.custid}&orderId=${item.id}`} 
                            className="m-1"
                            // state={{refunded: !!item.refunded, deleted: !!item.deleted, fullyPaid: !!item.fully_paid, hasItemsInActiveFitGtd: !!item.hasItemsInActiveFitGtd}}
                          >
                            <Button size="sm" variant="primary">
                              {
                                ((item.refunded || item.deleted || item.fully_paid) && 
                                item.hasItemsInActiveFitGtd === "0") 
                                ? 'View' : 'Edit'
                              }
                            </Button>
                          </Link>
                          
                          { (isAdmin() || isManager()) &&
                            <>
                              {
                                ((!item.deleted && !item.refunded && !item.fully_paid)) &&
                                <Button className="m-1" size="sm" variant="outline-danger" data-delete-id={item.id} onClick={handleDeleteClick}>Delete</Button>
                              }
                            </>
                          }
                          { isAdmin() &&
                            <>
                              {
                                (!!item.deleted) && 
                                <Button className="m-1" size="sm" variant="success" data-order-id={item.id} onClick={handleReOpenClick}>Reopen</Button>
                              }
                              {/* {
                                (!item.refunded && !item.deleted) && 
                                <Button className="m-1" size="sm" variant="secondary" data-order-id={item.id} onClick={handleRefundClick}>Refund</Button>
                              } */}
                            </>
                          }
                        </td>
                        {isRedDotBespoke() ? <td>
                          { (!item.refunded && !item.deleted) &&
                            <>
                              <Button className="m-1" size="sm" variant="info" data-order-id={item.id} data-notification-type='fitting' onClick={handleNotificationClick}>Send Fitting</Button>
                              <Button className="m-1" size="sm" variant="info" data-order-id={item.id} data-notification-type='collection' onClick={handleNotificationClick}>Send Collection</Button>
                              <div>
                                {item.fittingReminderActive ? 
                                <Badge variant="dark">Fitting Reminder Active</Badge>: ''}
                                {item.collectionReminderActive ? 
                                <Badge variant="dark">Collection Reminder Active</Badge>: ''}
                                {item.reminderActive ? 
                                <Badge variant="dark">Reminder Active</Badge>: ''}

                              </div>
                            </>
                          }
                        </td> : <></>
                        }
                        <td><Button className="m-1" size="sm" variant="secondary" data-order-id={item.id} data-notification-type='collection' onClick={handleOnLogViewClick}>View</Button></td>
                      </tr>
                    )
                  }) : ''
                }
              </tbody>
            </Table>  
          </Col>
        </Row>
        {pagination ? 
        <GenericPagination 
          pagination={pagination} 
          rowLimit={rowLimit}
          handleOnPaginationClick={handleOnPaginationClick}
        ></GenericPagination> : <></>}
        

      </Container>

      <StandardModal 
        onConfirm={handleModalConfirm}
        onClose={handleModalClose} 
        show={showModal}
        title="Delete Order"
        body={``}
      >
        Are you sure you want to delete Order ID: {(<strong>{orderItem.id}</strong>)} ?
      </StandardModal>

      <StandardModal 
        onConfirm={handleRefundModalConfirm}
        onClose={handleRefundModalClose} 
        show={showRefundModal}
        title="refund Order"
        body={``}
      >
        Are you sure you want to refund Order ID: {(<strong>{orderItem.id}</strong>)} ?
      </StandardModal>

      <StandardModal 
        onConfirm={handleSMSModalConfirm}
        onClose={handleSMSModalClose} 
        primaryButtonDisabledState={fittingConfirmDisabledState || collectionConfirmDisabledState}
        show={showNotificationModal}
        title="Send Notification"
        body={``}
      >
        Are you sure you want to send {(<strong>{notificationType.toUpperCase()}</strong>)} Notification?
      </StandardModal>

      <StandardModal 
        onClose={handleLogModalClose} 
        show={showLogModal}
        title="Log"
        body={``}
      >
        <ActionLog logs={actionLogs}></ActionLog>
      </StandardModal>
      
    </Layout>
  )
}

export default OrderHistory