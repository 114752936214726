import React from "react"
import {Table } from 'react-bootstrap'
import { 
  DDMMYYYYFormatWithTime, 
} from '../helpers/dateTime';
const ActionLog = ({logs}) => {
  const statusCodes = {
    0: 'Success', 1: 'Throttled',2:'Missing params', 3:'Invalid params',4:'Invalid credentials',
    5:'Internal error',6:'Invalid message',7:'Number barred',8:'Partner account barred',9:'Partner quota exceeded',
    11:'Account not enabled for REST',12:'Message too long',13:'Communication Failed',14:'Invalid Signature',
    15:'Illegal Sender Address - rejected',16:'Invalid TTL',19:'Facility not allowed',20:'Invalid Message class',
    23:'Bad callback :: Missing Protocol',29:'Non White-listed Destination',34:'Invalid or Missing Msisdn Param'
  }
  return (
    logs && Array.isArray(logs) && (
      <Table>
        <thead>
          <th>Name</th>
          <th>Type</th>
          <th>Timestamp</th>
          <th>Status</th>
        </thead>
        <tbody>
          {logs.map((log,index) => {
            return (
              <tr key={index}>
                <td>{log.name}</td>
                <td>{log.method}</td>
                <td>{DDMMYYYYFormatWithTime(log.timestamp)}</td>
                <td>{log.method === 'sms' ? statusCodes[log.status]: log.status }</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  )
}

export default ActionLog